import React from 'react'
import { Helmet } from 'react-helmet'
import Hero from '../components/Hero'
import LongText from '../components/LongText'
import Footer from '../components/utility/Footer'
import Navbar from '../components/utility/Navbar'

const text =
  <>
    <Helmet title="Zimmerei Müller - Impressum">
      <meta property="og:title" content="Zimmerei Müller - Impressum" />
      <meta
        property="og:description"
        content="Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich."
      />
      <meta property="og:url" content="https://holzbaumueller.de/impressum" />
      <meta
        name="description"
        content="Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich."
      />
    </Helmet>
    <h2>Angaben gemäß § 5 TMG</h2>
    <p>
      Zimmerei Müller GmbH<br />
      Lichtestr. 1<br />
      73667 Kaisersbach<br />
      Handelsregister: HRB 754738 Registergericht: Amtsgericht Stuttgart<br /><br />
      <b>Vertreten durch:</b><br />
      Reinhardt Müller
    </p>
    <h3>Kontakt</h3>
    <p>
      Telefon: 07184 2761<br />
      Telefax: 07184 2114<br />
      E-Mail: info@holzbaumueller.de
    </p>
    <h3>Umsatzsteuer-ID</h3>
    <p>
      Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE 303646678
    </p>
    <h3>Angaben zur Berufshaftpflichtversicherung</h3>
    <p>
      <b>Name und Sitz des Versicherers:</b><br />
      VHV Hannover
    </p>
    <h3>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h3>
    <p>
      Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
    </p>
  </>


const ImpressumPage = () => {
  return (
    <>
      <Navbar />
      <main>
        <Hero headline="Impressum" alt="" />
        <LongText content={text} />
      </main>
      <Footer />
    </>
  )
}

export default ImpressumPage